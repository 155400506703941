/* eslint-disable no-useless-escape */

export default {
  'USA': {
    states: [
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington|(District of Columbia)",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
    hierarchy: {
      "State Supreme Court": "(Supreme Court)",
      "Intermediate Appellate Courts": "Appellate|(Supreme Judicial)|Appeal|(Commonwealth Court)|(Court of Review)|(Military Commission Review)|(Veterans Claims)|(Judicial Panel on Multidistrict Litigation)",
      "Trial Courts": "(Tax Court)|(Superior Court)|(Court of Chancery)|(Court on the Judiciary)|(Criminal Court)|(Court of Common Pleas)|(Family Court)|(District Court)|Bankruptcy|(Land Court)|(Surrogate's Court)|(Court of Claims)|(Civil Court)|(Court of International Trade)|(Federal Claims)|(Foreign Intelligence Surveillance Court)"
    },
    courts: {
      'State Courts': [
        // Supreme
        { 'name': 'Alabama Supreme Court', 'id': 'ala' },
        { 'name': 'Alaska Supreme Court', 'id': 'alaska' },
        { 'name': 'Arizona Supreme Court', 'id': 'ariz' },
        { 'name': 'Arkansas Supreme Court', 'id': 'ark' },
        { 'name': 'California Supreme Court', 'id': 'cal' },
        { 'name': 'Colorado Supreme Court', 'id': 'colo' },
        { 'name': 'Connecticut Supreme Court', 'id': 'conn' },
        { 'name': 'Delaware Supreme Court', 'id': 'del' },
        { 'name': 'Florida Supreme Court', 'id': 'fla' },
        { 'name': 'Georgia Supreme Court', 'id': 'ga' },
        { 'name': 'Hawaii Supreme Court', 'id': 'haw' },
        { 'name': 'Idaho Supreme Court', 'id': 'idaho' },
        { 'name': 'Illinois Supreme Court', 'id': 'ill' },
        { 'name': 'Indiana Supreme Court', 'id': 'ind' },
        { 'name': 'Iowa Supreme Court', 'id': 'iowa' },
        { 'name': 'Kansas Supreme Court', 'id': 'kan' },
        { 'name': 'Kentucky Supreme Court', 'id': 'ky' },
        { 'name': 'Louisiana Supreme Court', 'id': 'la' },
        { 'name': 'Michigan Supreme Court', 'id': 'mich' },
        { 'name': 'Minnesota Supreme Court', 'id': 'minn' },
        { 'name': 'Mississippi Supreme Court', 'id': 'miss' },
        { 'name': 'Missouri Supreme Court', 'id': 'mo' },
        { 'name': 'Montana Supreme Court', 'id': 'mont' },
        { 'name': 'Nebraska Supreme Court', 'id': 'neb' },
        { 'name': 'Nevada Supreme Court', 'id': 'nev' },
        { 'name': 'New Hampshire Supreme Court', 'id': 'nh' },
        { 'name': 'New Jersey Supreme Court', 'id': 'nj' },
        { 'name': 'New Mexico Supreme Court', 'id': 'nm' },
        { 'name': 'New York Supreme Court', 'id': 'nysupct' },
        { 'name': 'North Carolina Supreme Court', 'id': 'nc' },
        { 'name': 'North Dakota Supreme Court', 'id': 'nd' },
        { 'name': 'Ohio Supreme Court', 'id': 'ohio' },
        { 'name': 'Oklahoma Supreme Court', 'id': 'okla' },
        { 'name': 'Oregon Supreme Court', 'id': 'or' },
        { 'name': 'Pennsylvania Supreme Court', 'id': 'pa' },
        { 'name': 'Rhode Island Supreme Court', 'id': 'ri' },
        { 'name': 'South Carolina Supreme Court', 'id': 'sc' },
        { 'name': 'South Dakota Supreme Court', 'id': 'sd' },
        { 'name': 'Tennessee Supreme Court', 'id': 'tenn' },
        { 'name': 'Texas Supreme Court', 'id': 'tex' },
        { 'name': 'Utah Supreme Court', 'id': 'utah' },
        { 'name': 'Vermont Supreme Court', 'id': 'vt' },
        { 'name': 'Virginia Supreme Court', 'id': 'va' },
        { 'name': 'Washington Supreme Court', 'id': 'wash' },
        { 'name': 'West Virginia Supreme Court', 'id': 'wva' },
        { 'name': 'Wisconsin Supreme Court', 'id': 'wis' },
        { 'name': 'Wyoming Supreme Court', 'id': 'wyo' },

        // intermediate appellate
        { 'name': 'Alabama Court of Civil Appeals', 'id': 'alacivapp' },
        { 'name': 'Alabama Court of Criminal Appeals', 'id': 'alacrimapp' },
        { 'name': 'Alaska Court of Appeals', 'id': 'alaskactapp' },
        { 'name': 'Arizona Court of Appeals', 'id': 'arizctapp' },
        { 'name': 'Arkansas Court of Appeals', 'id': 'arkctapp' },
        { 'name': 'California Court of Appeal', 'id': 'calctapp' },
        { 'name': 'California Appellate Division of the Superior Court', 'id': 'calappdeptsuper' },
        { 'name': 'Colorado Court of Appeals', 'id': 'coloctapp' },
        { 'name': 'Colorado Industrial Claim Appeals Office', 'id': 'coloworkcompcom' },
        { 'name': 'Connecticut Appellate Court', 'id': 'connappct' },
        { 'name': 'Florida District Court of Appeal', 'id': 'fladistctapp' },
        { 'name': 'Georgia Court of Appeals', 'id': 'gactapp' },
        { 'name': 'Hawaii Intermediate Court of Appeals', 'id': 'hawapp' },
        { 'name': 'Idaho Court of Appeals', 'id': 'idahoctapp' },
        { 'name': 'Illinois Appellate Court', 'id': 'illappct' },
        { 'name': 'Indiana Court of Appeals', 'id': 'indctapp' },
        { 'name': 'Iowa Court of Appeals', 'id': 'iowactapp' },
        { 'name': 'Kansas Court of Appeals', 'id': 'kanctapp' },
        { 'name': 'Kentucky Court of Appeals', 'id': 'kyctapp' },
        { 'name': 'Louisiana Court of Appeal', 'id': 'lactapp' },
        { 'name': 'Maine Supreme Judicial Court', 'id': 'me' },
        { 'name': 'Maryland Court of Appeals', 'id': 'md' },
        { 'name': 'Maryland Court of Special Appeals', 'id': 'mdctspecapp' },
        { 'name': 'Massachusetts Supreme Judicial Court', 'id': 'mass' },
        { 'name': 'Massachusetts Appeals Court', 'id': 'massappct' },
        { 'name': 'Michigan Court of Appeals', 'id': 'michctapp' },
        { 'name': 'Minnesota Court of Appeals', 'id': 'minnctapp' },
        { 'name': 'Mississippi Court of Appeals', 'id': 'missctapp' },
        { 'name': 'Missouri Court of Appeals', 'id': 'moctapp' },
        { 'name': 'Nebraska Court of Appeals', 'id': 'nebctapp' },
        { 'name': 'New Jersey Superior Court Appellate Division', 'id': 'njsuperctappdiv' },
        { 'name': 'New Mexico Court of Appeals', 'id': 'nmctapp' },
        { 'name': 'New York Court of Appeals', 'id': 'ny' },
        { 'name': 'New York Appellate Division', 'id': 'nyappdiv' },
        { 'name': 'New York Appellate Terms', 'id': 'nyappterm' },
        { 'name': 'North Carolina Court of Appeals', 'id': 'ncctapp' },
        { 'name': 'North Dakota Court of Appeals', 'id': 'ndctapp' },
        { 'name': 'Ohio Court of Appeals', 'id': 'ohioctapp' },
        { 'name': 'Oklahoma Court of Civil Appeals', 'id': 'oklacivapp' },
        { 'name': 'Oklahoma Court of Criminal Appeals', 'id': 'oklacrimapp' },
        { 'name': 'Oregon Court of Appeals', 'id': 'orctapp' },
        { 'name': 'Pennsylvania Commonwealth Court', 'id': 'pacommwct' },
        { 'name': 'South Carolina Court of Appeals', 'id': 'scctapp' },
        { 'name': 'Tennessee Court of Appeals', 'id': 'tennctapp' },
        { 'name': 'Tennessee Court of Criminal Appeals', 'id': 'tenncrimapp' },
        { 'name': "Tennessee Workers' Comp. Appeals Board", 'id': 'tennworkcompapp' },
        { 'name': 'Texas Court of Appeals', 'id': 'texapp' },
        { 'name': 'Texas Court of Criminal Appeals', 'id': 'texcrimapp' },
        { 'name': 'Texas Special Court of Review', 'id': 'texreview' },
        { 'name': 'Utah Court of Appeals', 'id': 'utahctapp' },
        { 'name': 'Virginia Court of Appeals', 'id': 'vactapp' },
        { 'name': 'Washington Court of Appeals', 'id': 'washctapp'},
        { 'name': 'District of Columbia Court of Appeals', 'id': 'dc' },
        { 'name': 'Wisconsin Court of Appeals', 'id': 'wisctapp' },

        // trial
        { 'name': 'Arizona Tax Court', 'id': 'ariztaxct' },
        { 'name': 'Connecticut Superior Court', 'id': 'connsuperct' },
        { 'name': 'Delaware Court of Chancery', 'id': 'delch' },
        { 'name': 'Delaware Court on the Judiciary', 'id': 'deljudct' },
        { 'name': 'Delaware Court of Common Pleas', 'id': 'delctcompl' },
        { 'name': 'Delaware Family Court', 'id': 'delfamct' },
        { 'name': 'Delaware Superior Court', 'id': 'delsuperct' },
        { 'name': 'Indiana Tax Court', 'id': 'indtc' },
        { 'name': 'Massachusetts District Court', 'id': 'massdistct' },
        { 'name': 'Massachusetts Land Court', 'id': 'masslandct' },
        { 'name': 'Massachusetts Superior Court', 'id': 'masssuperct' },
        { 'name': 'New Jersey Tax Court', 'id': 'njtaxct' },
        { 'name': 'New York City Civil Court', 'id': 'nycivct' },
        { 'name': 'New York City Criminal Court', 'id': 'nycrimct' },
        { 'name': 'New York Family Court', 'id': 'nyfamct' },
        { 'name': "New York Surrogate's Court", 'id': 'nysurct' },
        { 'name': 'North Carolina Superior Court', 'id': 'ncsuperct' },
        { 'name': 'Ohio Court of Claims', 'id': 'ohioctcl' },
        { 'name': 'Oklahoma Court on the Judiciary', 'id': 'oklacoj' },
        { 'name': 'Oregon Tax Court', 'id': 'ortc' },
        { 'name': 'Pennsylvania Superior Court', 'id': 'pasuperct' },
        {'name': 'Rhode Island Superior Court', 'id': 'risuperct' },
        { 'name': 'Vermont Superior Court', 'id': 'vtsuperct' },

        // others
        { 'name': 'Arkansas Attorney General Reports', 'id': 'arkag' },
        { 'name': "Arkansas Workers' Compensation Commission", 'id': 'arkworkcompcom' },
        {'name': 'California Attorney General Reports', 'id': 'calag' },
        { 'name': 'Colorado Attorney General Reports', 'id': 'coloag' },
        { 'name': 'Connecticut Compensation Review Board', 'id': 'connworkcompcom' },
        { 'name': 'Florida Attorney General Reports', 'id': 'flaag' },
        { 'name': 'Kansas Attorney General Reports', 'id': 'kanag' },
        { 'name': 'Louisiana Attorney General Reports', 'id': 'laag' },
        {'name': 'Maryland Attorney General Reports', 'id': 'mdag' },
        { 'name': 'Minnesota Attorney General Reports', 'id': 'minnag' },
        { 'name': 'Missouri Attorney General Reports', 'id': 'moag' },
        { 'name': 'Montana Attorney General Reports', 'id': 'montag' },
        { 'name': 'Montana Tax Appeal Board', 'id': 'monttc' },
        { 'name': 'Nebraska Attorney General Reports', 'id': 'nebag' },
        { 'name': 'New York Attorney General Reports', 'id': 'nyag' },
        { 'name': 'North Carolina Industrial Commission', 'id': 'ncworkcompcom' },
        { 'name': 'Oklahoma Attorney General Reports', 'id': 'oklaag' },
        { 'name': 'Oklahoma Judicial Ethics Advisory Panel', 'id': 'oklajeap' },
        { 'name': 'Pennsylvania Judicial Discipline', 'id': 'cjdpa' },
        { 'name': "Tennessee Court of Workers' Compensation Claims", 'id': 'tennworkcompcl' },
        { 'name': 'Texas Attorney General Reports', 'id': 'texag' },
        { 'name': 'Texas Judicial Panel on Multidistrict Litigation', 'id': 'texjpml' },
        { 'name': 'Washington Attorney General Reports', 'id': 'washag' },
        { 'name': 'Wisconsin Attorney General Reports', 'id': 'wisag' },
      ],
      'Federal Courts': [
        // trial - district
        { 'name': 'Alabama Middle District Court', 'id': 'almd' },
        { 'name': 'Alabama North District Court', 'id': 'alnd' },
        { 'name': 'Alabama South District Court', 'id': 'alsd' },
        { 'name': 'Alaska District Court', 'id': 'akd' },
        { 'name': 'Arizona District Court', 'id': 'azd' },
        { 'name': 'Arkansas Eastern District Court', 'id': 'ared' },
        { 'name': 'Arkansas West District Court', 'id': 'arwd' },
        { 'name': 'California Central District Court', 'id': 'cacd' },
        { 'name': 'California Eastern District Court', 'id': 'caed' },
        { 'name': 'California North District Court', 'id': 'cand' },
        { 'name': 'California South District Court', 'id': 'casd' },
        { 'name': 'Colorado District Court', 'id': 'cod' },
        { 'name': 'Connecticut District Court', 'id': 'ctd' },
        { 'name': 'Delaware District Court', 'id': 'ded' },
        { 'name': 'Florida Middle District Court', 'id': 'flmd' },
        { 'name': 'Florida North District Court', 'id': 'flnd' },
        { 'name': 'Florida South District Court', 'id': 'flsd' },
        { 'name': 'Georgia Middle District Court', 'id': 'gamd' },
        { 'name': 'Georgia North District Court', 'id': 'gand' },
        { 'name': 'Georgia South District Court', 'id': 'gasd' },
        { 'name': 'Hawaii District Court', 'id': 'hid' },
        { 'name': 'Idaho District Court', 'id': 'idd' },
        { 'name': 'Illinois Central District Court', 'id': 'ilcd' },
        { 'name': 'Illinois North District Court', 'id': 'ilnd' },
        { 'name': 'Illinois South District Court', 'id': 'ilsd' },
        { 'name': 'Indiana North District Court', 'id': 'innd' },
        { 'name': 'Indiana South District Court', 'id': 'insd' },
        { 'name': 'Iowa North District Court', 'id': 'iand' },
        { 'name': 'Iowa South District Court', 'id': 'iasd' },
        { 'name': 'Kansas District Court', 'id': 'ksd' },
        { 'name': 'Kentucky Eastern District Court', 'id': 'kyed' },
        { 'name': 'Kentucky West District Court', 'id': 'kywd' },
        { 'name': 'Louisiana Eastern District Court', 'id': 'laed' },
        { 'name': 'Louisiana Middle District Court', 'id': 'lamd' },
        { 'name': 'Louisiana West District Court', 'id': 'lawd' },
        { 'name': 'Maine District Court', 'id': 'med' },
        { 'name': 'Maryland District Court', 'id': 'mdd' },
        { 'name': 'Massachusetts District Court', 'id': 'mad' },
        { 'name': 'Michigan Eastern District Court', 'id': 'mied' },
        { 'name': 'Michigan West District Court', 'id': 'miwd' },
        { 'name': 'Minnesota District Court', 'id': 'mnd' },
        { 'name': 'Mississippi North District Court', 'id': 'msnd' },
        { 'name': 'Mississippi South District Court', 'id': 'mssd' },
        { 'name': 'Missouri Eastern District Court', 'id': 'moed' },
        { 'name': 'Missouri West District Court', 'id': 'mowd' },
        { 'name': 'Montana District Court', 'id': 'mtd' },
        { 'name': 'Nebraska District Court', 'id': 'ned' },
        { 'name': 'Nevada District Court', 'id': 'nvd' },
        { 'name': 'New Hampshire District Court', 'id': 'nhd' },
        { 'name': 'New Jersey District Court', 'id': 'njd' },
        { 'name': 'New Mexico District Court', 'id': 'nmd' },
        { 'name': 'New York Eastern District Court', 'id': 'nyed' },
        { 'name': 'New York North District Court', 'id': 'nynd' },
        { 'name': 'New York South District Court', 'id': 'nysd' },
        { 'name': 'New York West District Court', 'id': 'nywd' },
        { 'name': 'North Carolina Eastern District Court', 'id': 'nced' },
        { 'name': 'North Carolina Middle District Court', 'id': 'ncmd' },
        { 'name': 'North Carolina West District Court', 'id': 'ncwd' },
        { 'name': 'North Dakota District Court', 'id': 'ndd' },
        { 'name': 'Ohio North District Court', 'id': 'ohnd' },
        { 'name': 'Ohio South District Court', 'id': 'ohsd' },
        { 'name': 'Oklahoma Eastern District Court', 'id': 'oked' },
        { 'name': 'Oklahoma North District Court', 'id': 'oknd' },
        { 'name': 'Oklahoma West District Court', 'id': 'okwd' },
        { 'name': 'Oregon District Court', 'id': 'ord' },
        { 'name': 'Pennsylvania Eastern District Court', 'id': 'paed' },
        { 'name': 'Pennsylvania Middle District Court', 'id': 'pamd' },
        { 'name': 'Pennsylvania West District Court', 'id': 'pawd' },
        { 'name': 'Rhode Island District Court', 'id': 'rid' },
        { 'name': 'South Carolina District Court', 'id': 'scd' },
        { 'name': 'South Dakota District Court', 'id': 'sdd' },
        { 'name': 'Tennessee Eastern District Court', 'id': 'tned' },
        { 'name': 'Tennessee Middle District Court', 'id': 'tnmd' },
        { 'name': 'Tennessee West District Court', 'id': 'tnwd' },
        { 'name': 'Texas Eastern District Court', 'id': 'txed' },
        { 'name': 'Texas North District Court', 'id': 'txnd' },
        { 'name': 'Texas South District Court', 'id': 'txsd' },
        { 'name': 'Texas West District Court', 'id': 'txwd' },
        { 'name': 'Utah District Court', 'id': 'utd' },
        { 'name': 'Vermont District Court', 'id': 'vtd' },
        { 'name': 'Virginia Eastern District Court', 'id': 'vaed' },
        { 'name': 'Virginia West District Court', 'id': 'vawd' },
        { 'name': 'Washington Eastern District Court', 'id': 'waed' },
        { 'name': 'Washington West District Court', 'id': 'wawd' },
        { 'name': 'District of Columbia District Court', 'id': 'dcd' },
        { 'name': 'West Virginia North District Court', 'id': 'wvnd' },
        { 'name': 'West Virginia South District Court', 'id': 'wvsd' },
        { 'name': 'Wisconsin Eastern District Court', 'id': 'wied' },
        { 'name': 'Wisconsin West District Court', 'id': 'wiwd' },
        { 'name': 'Wyoming District Court', 'id': 'wyd' },

        // trial - bankruptcy
        { 'name': 'Alabama Middle District Bankruptcy Court', 'id': 'almb' },
        { 'name': 'Alabama North District Bankruptcy Court', 'id': 'alnb' },
        { 'name': 'Alabama South District Bankruptcy Court', 'id': 'alsb' },
        { 'name': 'Alaska Bankruptcy Court', 'id': 'akb' },
        { 'name': 'Arizona Bankruptcy Court', 'id': 'arb' },
        { 'name': 'Arkansas Eastern District Bankruptcy Court', 'id': 'areb' },
        { 'name': 'Arkansas West District Bankruptcy Court', 'id': 'arwb' },
        { 'name': 'California Central District Bankruptcy Court', 'id': 'cacb' },
        { 'name': 'California North District Bankruptcy Court', 'id': 'canb' },
        { 'name': 'California South District Bankruptcy Court', 'id': 'casb' },
        { 'name': 'California Eastern District Bankruptcy Court', 'id': 'caeb' },
        { 'name': 'Colorado Bankruptcy Court', 'id': 'cob' },
        { 'name': 'Connecticut Bankruptcy Court', 'id': 'ctb' },
        { 'name': 'Delaware Bankruptcy Court', 'id': 'deb' },
        { 'name': 'Florida Middle District Bankruptcy Court', 'id': 'flmb' },
        { 'name': 'Florida North District Bankruptcy Court', 'id': 'flnb' },
        { 'name': 'Florida South District Bankruptcy Court', 'id': 'flsb' },
        { 'name': 'Georgia Middle District Bankruptcy Court', 'id': 'gamb' },
        { 'name': 'Georgia North District Bankruptcy Court', 'id': 'ganb' },
        { 'name': 'Georgia South District Bankruptcy Court', 'id': 'gasb' },
        { 'name': 'Hawaii Bankruptcy Court', 'id': 'hib' },
        { 'name': 'Idaho Bankruptcy Court', 'id': 'idb' },
        { 'name': 'Illinois  Central District Bankruptcy Court', 'id': 'ilcb' },
        { 'name': 'Illinois North District Bankruptcy Court', 'id': 'ilnb' },
        { 'name': 'Illinois South District Bankruptcy Court', 'id': 'ilsb' },
        { 'name': 'Indiana North District Bankruptcy Court', 'id': 'innb' },
        { 'name': 'Indiana South District Bankruptcy Court', 'id': 'insb' },
        { 'name': 'Iowa North District Bankruptcy Court', 'id': 'ianb' },
        { 'name': 'Iowa South District Bankruptcy Court', 'id': 'iasb' },
        { 'name': 'Kansas Bankruptcy Court', 'id': 'ksb' },
        { 'name': 'Kentucky Eastern District Bankruptcy Court', 'id': 'kyeb' },
        { 'name': 'Kentucky West District Bankruptcy Court', 'id': 'kywb' },
        { 'name': 'Louisiana Eastern District Bankruptcy Court', 'id': 'laeb' },
        { 'name': 'Louisiana Middle District Bankruptcy Court', 'id': 'lamb' },
        { 'name': 'Louisiana West District Bankruptcy Court', 'id': 'lawb' },
        { 'name': 'Maine Bankruptcy Court', 'id': 'bapme' },
        { 'name': 'Maine Bankruptcy Court', 'id': 'meb' },
        { 'name': 'Maryland Bankruptcy Court', 'id': 'mdb' },
        { 'name': 'Massachusetts District Bankruptcy Court', 'id': 'mab' },
        { 'name': 'Massachusetts Bankruptcy Court', 'id': 'bapma' },
        { 'name': 'Michigan Eastern District Bankruptcy Court', 'id': 'mieb' },
        { 'name': 'Michigan West District Bankruptcy Court', 'id': 'miwb' },
        { 'name': 'Minnesota Bankruptcy Court', 'id': 'mnb' },
        { 'name': 'Mississippi North District Bankruptcy Court', 'id': 'msnb' },
        { 'name': 'Mississippi South District Bankruptcy Court', 'id': 'mssb' },
        { 'name': 'Missouri Eastern District Bankruptcy Court', 'id': 'moeb' },
        { 'name': 'Missouri West District Bankruptcy Court', 'id': 'mowb' },
        { 'name': 'Montana Bankruptcy Court', 'id': 'mtb' },
        { 'name': 'Nebraska Bankruptcy Court', 'id': 'nebraskab' },
        { 'name': 'Nevada Bankruptcy Court', 'id': 'nvb' },
        { 'name': 'New Hampshire Bankruptcy Court', 'id': 'nhb' },
        { 'name': 'New Jersey Bankruptcy Court', 'id': 'njb' },
        { 'name': 'New Mexico Bankruptcy Court', 'id': 'nmb' },
        { 'name': 'New York North District Bankruptcy Court', 'id': 'nynb' },
        { 'name': 'New York South District Bankruptcy Court', 'id': 'nysb' },
        { 'name': 'New York Eastern District Bankruptcy Court', 'id': 'nyeb' },
        { 'name': 'New York West District Bankruptcy Court', 'id': 'nywb' },
        { 'name': 'North Carolina Eastern District Bankruptcy Court', 'id': 'nceb' },
        { 'name': 'North Carolina Middle District Bankruptcy Court', 'id': 'ncmb' },
        { 'name': 'North Carolina West District Bankruptcy Court', 'id': 'ncwb' },
        { 'name': 'North Dakota Bankruptcy Court', 'id': 'ndb' },
        { 'name': 'Ohio North District Bankruptcy Court', 'id': 'ohnb' },
        { 'name': 'Ohio South District Bankruptcy Court', 'id': 'ohsb' },
        { 'name': 'Oklahoma Eastern District Bankruptcy Court', 'id': 'okeb' },
        { 'name': 'Oklahoma North District Bankruptcy Court', 'id': 'oknb' },
        { 'name': 'Oklahoma West District Bankruptcy Court', 'id': 'okwb' },
        { 'name': 'Oregon Bankruptcy Court', 'id': 'orb' },
        { 'name': 'Pennsylvania Eastern District Bankruptcy Court', 'id': 'paeb' },
        { 'name': 'Pennsylvania Middle District Bankruptcy Court', 'id': 'pamb' },
        { 'name': 'Pennsylvania West District Bankruptcy Court', 'id': 'pawb' },
        { 'name': 'Rhode Island Bankruptcy Court', 'id': 'rib' },
        { 'name': 'South Carolina Bankruptcy Court', 'id': 'scb' },
        { 'name': 'South Dakota Bankruptcy Court', 'id': 'sdb' },
        { 'name': 'Tennessee Eastern District Bankruptcy Court', 'id': 'tneb' },
        { 'name': 'Tennessee Middle District Bankruptcy Court', 'id': 'tnmb' },
        { 'name': 'Tennessee West District Bankruptcy Court', 'id': 'tnwb' },
        { 'name': 'Texas North District Bankruptcy Court', 'id': 'txnb' },
        { 'name': 'Texas South District Bankruptcy Court', 'id': 'txsb' },
        { 'name': 'Texas Eastern District Bankruptcy Court', 'id': 'txeb' },
        { 'name': 'Texas West District Bankruptcy Court', 'id': 'txwb' },
        { 'name': 'Utah Bankruptcy Court', 'id': 'utb' },
        { 'name': 'Vermont Bankruptcy Court', 'id': 'vtb' },
        { 'name': 'Virginia Eastern District Bankruptcy Court', 'id': 'vaeb' },
        { 'name': 'Virginia West District Bankruptcy Court', 'id': 'vawb' },
        { 'name': 'Washington Eastern District Bankruptcy Court', 'id': 'waeb' },
        { 'name': 'Washington West District Bankruptcy Court', 'id': 'wawb' },
        { 'name': 'District of Columbia Bankruptcy Court', 'id': 'dcb' },
        { 'name': 'West Virginia North District Bankruptcy Court', 'id': 'wvnb' },
        { 'name': 'West Virginia South District Bankruptcy Court', 'id': 'wvsb' },
        { 'name': 'Wisconsin Eastern District Bankruptcy Court', 'id': 'wieb' },
        { 'name': 'Wisconsin West District Bankruptcy Court', 'id': 'wiwb' },
        { 'name': 'Wyoming Bankruptcy Court', 'id': 'wyb' },
      ],
      'Others': [
        { 'name': 'Federal Supreme Court', 'id': 'scotus' },
        { 'name': 'Air Force Court of Criminal Appeals', 'id': 'afcca' },
        { 'name': 'Armed Services Board of Contract Appeals', 'id': 'asbca' },
        { 'name': 'Army Court of Criminal Appeals', 'id': 'acca' },
        { 'name': 'Board of Immigration Appeals', 'id': 'bia' },
        { 'name': "Board of Veterans' Appeals", 'id': 'bva' },
        { 'name': 'Court of Appeals for the Armed Forces', 'id': 'armfor' },
        { 'name': 'Navy-Marine Corps Court of Criminal Appeals', 'id': 'nmcca' },
        { 'name': 'Court of International Trade', 'id': 'cit' },
        { 'name': 'Federal Claims', 'id': 'uscfc' },
        { 'name': 'Attorneys General', 'id': 'ag' },
        { 'name': 'Office of Legal Counsel', 'id': 'olc' },

        { 'name': 'Foreign Intelligence Surveillance Court', 'id': 'fisc' },
        { 'name': 'Foreign Intelligence Surveillance Court of Review', 'id': 'fiscr' },
        { 'name': 'Judicial Panel on Multidistrict Litigation', 'id': 'jpml' },
        { 'name': 'Merit Systems Protection Board', 'id': 'mspb' },
        { 'name': 'Military Commission Review', 'id': 'mc' },

        { 'name': 'Guam District Court', 'id': 'gud' },
        { 'name': 'Guam Bankruptcy Courts', 'id': 'gub' },
        { 'name': 'Northern Mariana Islands District Court', 'id': 'nmid' },
        { 'name': 'Northern Mariana Islands Bankruptcy Court', 'id': 'nmib' },
        { 'name': 'Puerto Rico District Court', 'id': 'prd' },
        { 'name': 'Puerto Rico Bankruptcy Courts', 'id': 'prb' },
        { 'name': 'Virgin Islands District Court', 'id': 'vid' },
        { 'name': 'Virgin Islands Bankruptcy Courts', 'id': 'vib' },
        
        { 'name': '1st Circuit Bankruptcy Court', 'id': 'bap1' },
        { 'name': '2nd Circuit Bankruptcy Court', 'id': 'bap2' },
        { 'name': '6th Circuit Bankruptcy Court', 'id': 'bap6' },
        { 'name': '8th Circuit Bankruptcy Court', 'id': 'bap8' },
        { 'name': '9th Circuit Bankruptcy Court', 'id': 'bap9' },
        { 'name': '10th Circuit Bankruptcy Court', 'id': 'bap10' },

        { 'name': 'Federal Circuit Court of Appeals', 'id': 'cafc' },
        { 'name': 'District of Columbia Circuit Court of Appeals', 'id': 'cadc' },
        { 'name': '1st Circuit Court of Appeals', 'id': 'ca1' },
        { 'name': '2nd Circuit Court of Appeals', 'id': 'ca2' },
        { 'name': '3rd Circuit Court of Appeals', 'id': 'ca3' },
        { 'name': '4th Circuit Court of Appeals', 'id': 'ca4' },
        { 'name': '5th Circuit Court of Appeals', 'id': 'ca5' },
        { 'name': '6th Circuit Court of Appeals', 'id': 'ca6' },
        { 'name': '7th Circuit Court of Appeals', 'id': 'ca7' },
        { 'name': '8th Circuit Court of Appeals', 'id': 'ca8' },
        { 'name': '9th Circuit Court of Appeals', 'id': 'ca9' },
        { 'name': '10th Circuit Court of Appeals', 'id': 'ca10' },
        { 'name': '11th Circuit Court of Appeals', 'id': 'ca11' },

        { 'name': 'U.S. Judicial Conference Committee', 'id': 'usjc' },
        { 'name': 'U.S. Tax Court', 'id': 'tax' },
        { 'name': 'Veterans Claims', 'id': 'cavc' },
      ],
    },
  },

  'UK': {
    states: [
      "England and Wales|(\(IPEC\))|(Care Standards Tribunal)|(Competition Appeals Tribunal)|(Employment Appeal Tribunal)|(Financial Services and Markets Tribunals)|(Information Tribunal)|(Lands Tribunal)|(VAT)|(Administrative Court)|(Exchequer Court)|(Senior Court Costs Office)|(Technology and Construction Court)",
      "Northern Ireland",
      "Scotland|Scottish",
    ],
    hierarchy: {
      "Supreme Court": "(Supreme Court)|(European Union)",
      "Court of Appeals": "(House of Lords)|(Court of Appeal)|(Appeal Court)|(Court of Session)|(Court of Criminal Appeal)",
      "High Courts": "(High Court)|(Commercial Court)|(Family Court)|(Patents Court)|(Queen's Bench Division)|(King's Bench Division)|(Administrative Court)|(Master's)",
      "Crown Courts": "(Crown Court)",
      "County Courts": "(County Court)|(\(IPEC\))",
      "Magistrates' Courts": "(Magistrates' Court)",
      "Specialized Courts": "Tribunal|(Court of Protection)|(Technology and Construction Court)"
    },
    courts: {
      "United Kingdom Jurisdiction": [
        // supreme
        { 'name': 'Supreme Court of Ireland Decisions', 'id': 'ie/cases/IESC' },

        // appellate
        { 'name': 'Court of Appeal in England and Wales (Civil Division) Decisions', 'id': 'ew/cases/EWCA/Civ' },
        { 'name': 'Court of Appeal in England and Wales (Criminal Division) Decisions', 'id': 'ew/cases/EWCA/Crim' },
        { 'name': 'Court of Appeal in England and Wales Decisions', 'id': 'ew/cases/EWCA' },
        { 'name': 'Court of Appeal in Northern Ireland Decisions', 'id': 'nie/cases/NICA' },
        { 'name': 'Scottish Sheriff Appeal Courts (Civil Division) Decisions', 'id': 'scot/cases/ScotSAC/Civ' },
        { 'name': 'Scottish Sheriff Appeal Courts (Criminal Division) Decisions', 'id': 'scot/cases/ScotSAC/Crim' },
        { 'name': 'Scottish Court of Session Decisions', 'id': 'scot/cases/ScotCS' },

        // high court
        { 'name': 'Admiralty Division of the High Court of Justice in England and Wales', 'id': 'ew/cases/EWHC/Admlty' },
        { 'name': 'Chancery Division of the High Court of Justice in England and Wales', 'id': 'ew/cases/EWHC/Ch' },
        { 'name': 'Commercial Court Decisions', 'id': 'ew/cases/EWHC/Comm' },
        { 'name': 'England and Wales Family Court', 'id': 'ew/cases/EWFC' },
        { 'name': 'England and Wales Family Court (High Court Judges)', 'id': 'ew/cases/EWFC/HCJ' },
        { 'name': 'England and Wales Family Court (Other Judges)', 'id': 'ew/cases/EWHC/OJ' },
        { 'name': 'High Court of England and Wales', 'id': 'ew/cases/EWHC' },
        { 'name': 'Family Division of the High Court in England and Wales Decisions', 'id': 'ew/cases/EWHC/Fam' },
        { 'name': 'England and Wales Patents Court Decisions', 'id': 'ew/cases/EWHC/Patents' },
        { 'name': "England and Wales Queen's Bench Division Decisions", 'id': 'ew/cases/EWHC/QB' },
        { 'name': "England and Wales King's Bench Division Decisions", 'id': 'ew/cases/EWHC/KB' },
        { 'name': 'Administrative Court Decisions', 'id': 'ew/cases/EWHC/Admin' },
        { 'name': 'Mercantile Court of the High Court in England and Wales Decisions', 'id': 'ew/cases/EWHC/Mercantile' },

        { 'name': 'Chancery Division of the High Court of Justice in Northern Ireland', 'id': 'nie/cases/NIHC/Ch' },
        { 'name': 'Family Division of the High Court in Northern Ireland Decisions', 'id': 'nie/cases/NIHC/Fam' },
        { 'name': 'High Court of Northern Ireland Decisions', 'id': 'nie/cases/NIHC' },
        { 'name': "Northern Ireland Queen's Bench Division Decisions", 'id': 'nie/cases/NIHC/QB' },
        { 'name': "Northern Ireland Master's Decisions", 'id': 'nie/cases/NIHC/Master' },

        { 'name': 'Scottish High Court of Justiciary Decisons', 'id': 'scot/cases/ScotHC' },

        // crown court
        { 'name': 'Crown Court for Northern Ireland Decisions', 'id': 'nie/cases/NICC' },

        // county court
        { 'name': 'England and Wales County Court (Family)', 'id': 'ew/cases/EWCC/Fam' },
        { 'name': 'Patents County Court/Intellectual Property Enterprise Court(IPEC)', 'id': 'ew/cases/EWPCC' },

        // magistrates court
        { 'name': "England and Wales Magistrates' Court (Family)", 'id': 'ew/cases/EWMC/FPC' },

        // specialized court
        { 'name': 'Care Standards Tribunal', 'id': 'ew/cases/EWCST' },
        { 'name': 'Competition Appeals Tribunal', 'id': 'uk/cases/CAT' },
        { 'name': 'Employment Appeal Tribunal', 'id': 'uk/cases/UKEAT' },
        { 'name': 'Financial Services and Markets Tribunals Decisions', 'id': 'uk/cases/UKFSM' },
        { 'name': 'Information Tribunal and the National Security Appeals Panel', 'id': 'uk/cases/UKIT' },
        { 'name': 'Lands Tribunal', 'id': 'ew/cases/EWLands' },
        { 'name': 'VAT & Duties Tribunals Decisions', 'id': 'uk/cases/UKVAT' },
        { 'name': 'England and Wales Court of Protection', 'id': 'ew/cases/EWCOP' },
        { 'name': 'Technology and Construction Court Decisions', 'id': 'ew/cases/EWHC/TCC' },
        
        { 'name': 'Industrial Tribunals Northern Ireland Decisions', 'id': 'nie/cases/NIIT' },
        { 'name': 'Fair Employment Tribunal Northern Ireland Decisions', 'id': 'nie/cases/NIFET' },
        { 'name': "Northern Ireland Social Security and Child Support Commissioners' Decisions", 'id': 'nie/cases/NISSCSC' },

        // others
        { 'name': 'Exchequer Court Decisions', 'id': 'ew/cases/EWHC/Exch' },
        { 'name': 'Senior Court Costs Office Decisions', 'id': 'ew/cases/EWHC/Costs' },
        { 'name': "England and Wales Social Security and Child Support Commissioners' Decisions", 'id': 'uk/cases/UKSSCSC' },
        
        { 'name': 'Scotland Case Law', 'id': 'scot/cases' },
        { 'name': 'Scottish Information Commissioner Decisions', 'id': 'scot/cases/ScotIC' },
        { 'name': 'Scottish Sheriff Court Decisions', 'id': 'scot/cases/ScotSC' },

      ],
      "Others": [
        { 'name': 'Supreme Court Decisions', 'id': 'uk/cases/UKSC' },
        { 'name': 'St Helena Supreme Court', 'id': 'sh/cases/SHSC' },
        { 'name': 'Court of Justice of the European Union (including the General Court)', 'id': 'eu/cases/EUECJ' },
        { 'name': 'House of Lords Decisions', 'id': 'uk/cases/UKHL' },
        { 'name': 'European Court of Human Rights', 'id': 'eu/cases/ECHR' },
        { 'name': 'Europe Case Law', 'id': 'eu/cases' },

        { 'name': 'Court of Appeal in Ireland', 'id': 'ie/cases/IECA' },
        { 'name': 'Court of Criminal Appeal in Ireland', 'id': 'ie/cases/IECCA' },
        { 'name': 'High Court of Ireland Decisions', 'id': 'ie/cases/IEHC' },
        { 'name': 'Ireland Case Law', 'id': 'ie/cases' },
        { 'name': 'Irish Competition Authority Decisions', 'id': 'ie/cases/IECompA' },
        { 'name': 'Irish Data Protection Commission Case Studies', 'id': 'ie/cases/IEDPC' },
        { 'name': "Irish Information Commissioner's Decisions", 'id': 'ie/cases/IEIC' },

        { 'name': 'Jersey Law Reports', 'id': 'je/cases/JLR' },
        { 'name': 'Jersey Unreported Judgments', 'id': 'je/cases/UR' },
        { 'name': 'Jersey Case Law', 'id': 'je/cases' },
        // { 'name': 'Jersey: All Case Law', 'id': 'sh/cases' }, // [!!]

        { 'name': 'Nominet UK Dispute Resolution Service', 'id': 'uk/cases/DRS' },
        { 'name': 'Privy Council Decisions', 'id': 'uk/cases/UKPC' },
        { 'name': 'Special Commissioners of Income Tax Decisions', 'id': 'uk/cases/UKSPC' },
        { 'name': 'Special Immigrations Appeals Commission', 'id': 'uk/cases/SIAC' },
      ],
    },
  },

  'AUS': {
    states: [
      "Australian Capital Territory|(ACT )",
      "New South Wales|(\(NSW\))",
      "Victoria|Victorian",
      "Queensland",
      "South Australia|(South Australian)",
      "Western Australia|(Western Australian)",
      "Tasmania|Tasmanian"
    ],
    hierarchy: {
      "High Court": "(High Court)",
      "Federal Court": "(Federal Court)",
      "Family Court": "(Family Court)",
      "Federal Circuit Court": "(Federal Circuit Court)",
      "Intermediate Courts": "(Supreme Court)|(Superior Court)|(District Court)|(County Court)",
      "Magistrates' Courts": "(Magistrates Court)|(Magistrate)|(Local Court)",
    },
    courts: {
      'Australia Jurisdiction': [
        //family
        { 'name': 'Family Court of Western Australia  Magistrates Decisions 2008', 'id': 'wa/FCWAM' },
        { 'name': 'Family Court of Western Australia 2004', 'id': 'wa/FCWA' },

        // intermediate
        { 'name': "Knox's New South Wales Supreme Court Reports 18731877", 'id': 'nsw/NSWKnoxRp' },
        { 'name': "Legge's Supreme Court Cases (NSW) 18301863", 'id': 'nsw/NSWLeggeSC' },
        { 'name': 'Superior Courts of New South Wales 17881899', 'id': 'nsw/NSWSupC' },
        { 'name': 'Superior Courts of Tasmania 18241843', 'id': 'tas/TASSupC' },
        { 'name': 'Superior Courts of Western Australia 18401849', 'id': 'wa/WASupC' },
        { 'name': 'Supreme Court of New South Wales  Court of Appeal 1988', 'id': 'nsw/NSWCA' },
        { 'name': 'Supreme Court of New South Wales  Court of Criminal Appeal 1998', 'id': 'nsw/NSWCCA' },
        { 'name': 'Supreme Court of New South Wales 1993', 'id': 'nsw/NSWSC' },
        { 'name': 'Supreme Court of Norfolk Island 1984', 'id': 'nf/NFS' },
        { 'name': 'Supreme Court of Queensland  Court of Appeal 1992 ', 'id': 'qld/QCA' },
        { 'name': 'Supreme Court of Queensland 1994 ', 'id': 'qld/QSC' },
        { 'name': 'Supreme Court of South Australia  Court of Appeal 2021', 'id': 'sa/SASCA' },
        { 'name': 'Supreme Court of South Australia  Full Court 20102021', 'id': 'sa/SASCFC' },
        { 'name': 'Supreme Court of South Australia 1977 ', 'id': 'sa/SASC' },
        { 'name': 'Supreme Court of Tasmania  Court of Criminal Appeal 2010', 'id': 'tas/TASCCA' },
        { 'name': 'Supreme Court of Tasmania  Full Court 2010', 'id': 'tas/TASFC' },
        { 'name': 'Supreme Court of Tasmania 1985', 'id': 'tas/TASSC' },
        { 'name': 'Supreme Court of Victoria  Court of Appeal 1998', 'id': 'vic/VSCA' },
        { 'name': 'Supreme Court of Victoria (Unreported Judgments) 19531998', 'id': 'vic/VicSC' },
        { 'name': 'Supreme Court of Victoria 1994 ', 'id': 'vic/VSC' },
        { 'name': 'Supreme Court of Western Australia  Court of Appeal 1998', 'id': 'wa/WASCA' },
        { 'name': 'Supreme Court of Western Australia 1964', 'id': 'wa/WASC' },
        { 'name': 'Supreme Court of the Australian Capital Territory  Court of Appeal 2002', 'id': 'act/ACTCA' },
        { 'name': 'Supreme Court of the Australian Capital Territory (Full Court) 2014', 'id': 'act/ACTSCFC' },
        { 'name': 'Supreme Court of the Australian Capital Territory 1986', 'id': 'act/ACTSC' },
        { 'name': 'Supreme Court of the Northern Territory  Court of Appeal 1986', 'id': 'nt/NTCA' },
        { 'name': 'Supreme Court of the Northern Territory  Court of Criminal Appeal 1986', 'id': 'nt/NTCCA' },
        { 'name': 'Supreme Court of the Northern Territory  Full Court 1986', 'id': 'nt/NTSCFC' },
        { 'name': 'Supreme Court of the Northern Territory 1986', 'id': 'nt/NTSC' },

        { 'name': 'County Court of Victoria 1993 ', 'id': 'vic/VCC' },
        { 'name': 'District Court of New South Wales 1992', 'id': 'nsw/NSWDC' },
        { 'name': 'District Court of South Australia 1992 ', 'id': 'sa/SADC' },
        { 'name': 'District Court of Western Australia 1999', 'id': 'wa/WADC' },
        { 'name': 'Queensland District Court 1998', 'id': 'qld/QDC' },

        // magistrate
        { 'name': "Chief Industrial Magistrate's Court of New South Wales 1999", 'id': 'nsw/NSWCIMC' },
        { 'name': 'Childrens Court of Queensland (Magistrates) 2007', 'id': 'qld/QChCM' },
        { 'name': 'Magistrates Court of Queensland 2006', 'id': 'qld/QMC' },
        { 'name': 'Magistrates Court of Tasmania 2002', 'id': 'tas/TASMC' },
        { 'name': 'Magistrates Court of the Australian Capital Territory 2012', 'id': 'act/ACTMC' },
        { 'name': 'Magistrates Court of the Northern Territory 2002', 'id': 'nt/NTMC' },
        { 'name': "Magistrates' Court of Victoria 2006", 'id': 'vic/VMC' },

        // others
        { 'name': 'ACT Civil and Administrative Tribunal 2009', 'id': 'act/ACAT' },
        { 'name': 'ACT Health Professions Tribunal 20062009', 'id': 'act/ACTHPT' },
        { 'name': 'ACT Ombudsman  Freedom of Information 2018', 'id': 'act/ACTOFOI' },
        { 'name': 'Administrative Appeals Tribunal of the ACT 19932009', 'id': 'act/ACTAAT' },
        { 'name': 'Administrative Appeals Tribunal Reports (Victoria) 19861998', 'id': 'vic/VicAATRp' },
        { 'name': 'Administrative Decisions Tribunal Appeal Panel of New South Wales 19992013', 'id': 'nsw/NSWADTAP' },
        { 'name': 'Administrative Decisions Tribunal of New South Wales 19992013', 'id': 'nsw/NSWADT' },
        { 'name': 'AntiDiscrimination Tribunal Queensland 19922009', 'id': 'qld/QADT' },
        { 'name': 'AntiDiscrimination Tribunal of Tasmania 2000', 'id': 'tas/TASADT' },
        { 'name': 'Building Appeals Board of Victoria 2018', 'id': 'vic/VBAB' },
        { 'name': 'Childrens Court of Queensland 2004', 'id': 'qld/QChC' },
        { 'name': 'Chiropractic and Osteopathy Board of South Australia  Court Prosecutions 1998', 'id': 'sa/SACHOSBCP' },
        { 'name': 'Chiropractic and Osteopathy Board of South Australia  Disciplinary Decisions 1992', 'id': 'sa/SACHOSB' },
        { 'name': 'Chiropractors Tribunal of New South Wales 2008', 'id': 'nsw/NSWCHT' },
        { 'name': 'Civil and Administrative Tribunal of New South Wales  Administrative and Equal Opportunity Division 2014', 'id': 'nsw/NSWCATAD' },
        { 'name': 'Civil and Administrative Tribunal of New South Wales  Appeal Panel 2014', 'id': 'nsw/NSWCATAP' },
        { 'name': 'Civil and Administrative Tribunal of New South Wales  Consumer and Commercial Division 2014', 'id': 'nsw/NSWCATCD' },
        { 'name': 'Civil and Administrative Tribunal of New South Wales  Guardianship Division 2014', 'id': 'nsw/NSWCATGD' },
        { 'name': 'Civil and Administrative Tribunal of New South Wales  Occupational Division 2014', 'id': 'nsw/NSWCATOD' },
        { 'name': 'Community Services Appeals Tribunal of New South Wales 1998', 'id': 'nsw/csat' },
        { 'name': 'Compensation Court of New South Wales 19852003', 'id': 'nsw/NSWCC' },
        { 'name': 'Consumer, Trader and Tenancy Tribunal of New South Wales 20022013', 'id': 'nsw/NSWCTTT' },
        { 'name': 'Coroners Court of New South Wales 1979', 'id': 'nsw/NSWCorC' },
        { 'name': 'Coroners Court of Queensland 2004', 'id': 'qld/QldCorC' },
        { 'name': 'Coroners Court of South Australia 2000', 'id': 'sa/SACorC' },
        { 'name': 'Coroners Court of Victoria 2002', 'id': 'vic/VicCorC' },
        { 'name': 'Coroners Court of Western Australia 2012', 'id': 'wa/WACorC' },
        { 'name': 'Coroners Court of the Australian Capital Territory 2013', 'id': 'act/ACTCD' },
        { 'name': 'Criminal Injuries Compensation Assessor of Western Australia 2004 ', 'id': 'wa/WACIC' },

        { 'name': 'Dental Board of South Australia 2006', 'id': 'sa/SADB' },
        { 'name': 'Dental Practice Board of Victoria 2000', 'id': 'vic/VDPB' },
        { 'name': 'Dental Tribunal of New South Wales 2009', 'id': 'nsw/NSWDT' },
        { 'name': 'Discrimination Tribunal of the ACT 19982009', 'id': 'act/ACTDT' },
        { 'name': 'Drug Court of New South Wales 1999', 'id': 'nsw/NSWDRGC' },
        { 'name': 'Dust Diseases Tribunal of New South Wales 1989', 'id': 'nsw/NSWDDT' },
        { 'name': 'Environment Resources and Development Court of South Australia 1996 ', 'id': 'sa/SAERDC' },
        { 'name': 'Equal Opportunity Tribunal of South Australia 2004', 'id': 'sa/SAEOT' },
        { 'name': 'Fair Trading Tribunal of New South Wales 19992001', 'id': 'nsw/NSWFTT' },
        { 'name': 'Forest Practices Tribunal of Tasmania 1994', 'id': 'tas/TASFPT' },
        { 'name': 'Guardianship Tribunal of New South Wales 19962013', 'id': 'nsw/NSWGT' },
        { 'name': 'Guardianship and Administration Board of Western Australia 20042005', 'id': 'wa/WAGAB' },
        { 'name': 'Health Practitioners Tribunal of South Australia 2010', 'id': 'sa/SAHPT' },
        { 'name': 'Heritage Council of Victoria 2011', 'id': 'vic/VHerCl' },
        { 'name': 'Industrial Court of New South Wales 2014', 'id': 'nsw/NSWIC' },
        { 'name': 'Industrial Court of Queensland 2014', 'id': 'qld/ICQ' },
        { 'name': 'Industrial Court of the Australian Capital Territory 2014', 'id': 'act/ACTIC' },
        { 'name': 'Industrial Relations Commission of New South Wales 1985', 'id': 'nsw/NSWIRComm' },
        { 'name': 'Land Appeal Court of Queensland 1985', 'id': 'qld/QLAC' },
        { 'name': 'Land Court of Queensland 1989', 'id': 'qld/QLC' },
        { 'name': 'Land and Environment Court of New South Wales 1987', 'id': 'nsw/NSWLEC' },
        { 'name': 'Land and Resources Tribunal of Queensland 20002007', 'id': 'qld/QLRT' },
        { 'name': 'Law Reports (New South Wales) 18561900', 'id': 'nsw/NSWLawRp' },
        { 'name': 'Legal Profession Disciplinary Tribunal of Tasmania 2018', 'id': 'tas/TASLPDT' },
        { 'name': 'Licensing Court of South Australia 1994 ', 'id': 'sa/SALC' },
        { 'name': 'Liquor and Gambling Commissioner of South Australia 2002', 'id': 'sa/SALGCmr' },
        { 'name': 'Medical Board of South Australia 2005', 'id': 'sa/SAMB' },
        { 'name': 'Medical Board of Western Australia 2010', 'id': 'wa/WAMB' },
        { 'name': 'Medical Board of the ACT  Professional Standards Panel Outcomes 2005', 'id': 'act/ACTMBPSP' },
        { 'name': 'Medical Practitioners Board of Victoria  Formal Hearings 2001', 'id': 'vic/VMPB' },
        { 'name': 'Medical Practitioners Board of Victoria  Professional Standards Panel Hearings 2009', 'id': 'vic/VMPBPSP' },

        { 'name': 'New South Wales Industrial Gazettes 19021950', 'id': 'nsw/NSWIndGaz' },
        { 'name': 'New South Wales Medical Professional Standards Committee 2008', 'id': 'nsw/NSWMPSC' },
        { 'name': 'New South Wales Medical Tribunal 20102013', 'id': 'nsw/NSWMT' },
        { 'name': 'New South Wales Mental Health Review Tribunal 2013', 'id': 'nsw/NSWMHRT' },
        { 'name': 'New South Wales Privacy Commissioner Cases 1997', 'id': 'nsw/NSWPrivCmr' },
        { 'name': 'New South Wales State Insurance Regulatory Authority Dispute Resolution Service 2020', 'id': 'nsw/NSWSIRADRS' },
        { 'name': 'Nursing and Midwifery Professional Standards Committee of New South Wales 2011', 'id': 'nsw/NSWNMPSC' },
        { 'name': 'Nursing and Midwifery Tribunal of New South Wales 2007', 'id': 'nsw/NSWNMT' },
        { 'name': 'Office of the Victorian Privacy Commissioner Case Notes 2003', 'id': 'vic/VPrivCmr' },
        { 'name': 'Optometry Tribunal of New South Wales 2012', 'id': 'nsw/NSWOPT' },
        { 'name': 'Osteopathy Tribunal of New South Wales 2010', 'id': 'nsw/NSWOST' },

        { 'name': "Pelham's South Australian Reports 18651866", 'id': 'sa/SAPelhamRp' },
        { 'name': 'Personal Injury Commission of New South Wales  Medical Appeal Panel and Review Panel 2021', 'id': 'nsw/NSWPICMP' },
        { 'name': 'Personal Injury Commission of New South Wales  Merit Review 2021', 'id': 'nsw/NSWPICMR' },
        { 'name': 'Personal Injury Commission of New South Wales  Merit Review Panel 2021', 'id': 'nsw/NSWPICMRP' },
        { 'name': 'Personal Injury Commission of New South Wales  Presidential 2021', 'id': 'nsw/NSWPICPD' },
        { 'name': 'Personal Injury Commission of New South Wales 2021', 'id': 'nsw/NSWPIC' },
        { 'name': 'Pharmacy Board of New South Wales 19902010', 'id': 'nsw/NSWPB' },
        { 'name': 'Pharmacy Board of South Australia 2008', 'id': 'sa/SAPHB' },
        { 'name': 'Pharmacy Tribunal of New South Wales 2009', 'id': 'nsw/NSWPHT' },
        { 'name': 'Physiotherapists Registration Board of Victoria 2008', 'id': 'vic/VPYRB' },
        { 'name': 'Physiotherapists Tribunal of New South Wales 2007', 'id': 'nsw/NSWPYT' },
        { 'name': 'Planning Appeals Board Reports (Victoria) 19791987', 'id': 'vic/VicPABRp' },
        { 'name': 'Planning Panels Victoria 1988', 'id': 'vic/PPV' },
        { 'name': 'Podiatry Board of South Australia 2007', 'id': 'sa/SAPDB' },
        { 'name': 'Podiatry Tribunal of New South Wales 2010', 'id': 'nsw/NSWPDT' },
        { 'name': 'Psychologists Registration Board of Victoria 1999', 'id': 'vic/VPSRB' },
        { 'name': 'Psychologists Tribunal of New South Wales 2007', 'id': 'nsw/NSWPST' },
        { 'name': 'Queensland Body Corporate and Community Management Commissioner  Adjudicators Orders 2000', 'id': 'qld/QBCCMCmr' },
        { 'name': 'Queensland Building Tribunal 19942003', 'id': 'qld/QBT' },
        { 'name': 'Queensland Children Services Tribunal 20032009', 'id': 'qld/QCST' },
        { 'name': 'Queensland Civil and Administrative Tribunal 2009', 'id': 'qld/QCAT' },
        { 'name': 'Queensland Civil and Administrative Tribunal Appeals 2010', 'id': 'qld/QCATA' },
        { 'name': 'Queensland Commercial and Consumer Tribunal  Architects List 20042009', 'id': 'qld/QCCTA' },
        { 'name': 'Queensland Commercial and Consumer Tribunal  Body Corporate and Community Management List 20072009', 'id': 'qld/QCCTBCCM' },
        { 'name': 'Queensland Commercial and Consumer Tribunal  Building List 20032009', 'id': 'qld/QCCTB' },
        { 'name': 'Queensland Commercial and Consumer Tribunal  Engineers List 20032009', 'id': 'qld/QCCTE' },
        { 'name': 'Queensland Commercial and Consumer Tribunal  Gaming List 20042009', 'id': 'qld/QCCTG' },
        { 'name': 'Queensland Commercial and Consumer Tribunal  Liquor List 20032009', 'id': 'qld/QCCTL' },
        { 'name': 'Queensland Commercial and Consumer Tribunal  Manufactured Homes List 20042009', 'id': 'qld/QCCTMH' },
        { 'name': 'Queensland Commercial and Consumer Tribunal  Plumbers and Drainers List 20042009', 'id': 'qld/QCCTPD' },
        { 'name': 'Queensland Commercial and Consumer Tribunal  Property Agents and Motor Dealers List 20032009', 'id': 'qld/QCCTPAMD' },
        { 'name': 'Queensland Commercial and Consumer Tribunal  Retirement Villages List 20032009', 'id': 'qld/QCCTRV' },
        { 'name': 'Queensland Guardianship and Administration Tribunal 20002009', 'id': 'qld/QGAAT' },
        { 'name': 'Queensland Industrial Court 19992009', 'id': 'qld/QIC' },
        { 'name': 'Queensland Industrial Gazettes 19151950', 'id': 'qld/QldIndGaz' },
        { 'name': 'Queensland Industrial Relations Commission 19992014', 'id': 'qld/QIRComm' },
        { 'name': 'Queensland Industrial Relations Commission 2014', 'id': 'qld/QIRC' },
        { 'name': 'Queensland Information Commissioner 1993', 'id': 'qld/QICmr' },
        { 'name': 'Queensland Legal Practice Tribunal 20042009', 'id': 'qld/QLPT' },
        { 'name': 'Queensland Liquor Appeals Tribunal 19922003', 'id': 'qld/QLAT' },
        { 'name': 'Queensland Mental Health Court 2002', 'id': 'qld/QMHC' },
        { 'name': 'Queensland Mining Wardens 19932000', 'id': 'qld/QMW' },
        { 'name': 'Queensland Nursing Tribunal 2004', 'id': 'qld/QNT' },
        { 'name': 'Queensland Planning and Environment Court 1999', 'id': 'qld/QPEC' },
        { 'name': 'Queensland Property Agents and Motor Dealers Tribunal 20022003', 'id': 'qld/QPAMDT' },
        { 'name': 'Queensland Racing Appeals Authority 20022003', 'id': 'qld/QRAA' },
        { 'name': 'Queensland Retirement Villages Tribunal 20012002', 'id': 'qld/QRVT' },

        { 'name': 'Racing Appeals Tribunal of Queensland 20032009', 'id': 'qld/QRAT' },
        { 'name': 'Residential Tenancies Tribunal of the ACT 19982009', 'id': 'act/ACTRTT' },
        { 'name': 'Residential Tribunal of New South Wales 19872002', 'id': 'nsw/NSWR' },
        { 'name': 'Resource Management and Planning Appeal Tribunal of Tasmania 1994', 'id': 'tas/TASRMPAT' },
        { 'name': 'Resource Planning and Development Commission of Tasmania 20042009', 'id': 'tas/TASRPDComm' },
        { 'name': 'Social Security Appeals Tribunal of Australia  Review of Child Support Agency 20072015', 'id': 'cth/SSATACSA' },
        { 'name': 'South Australian Civil and Administrative Tribunal 2015', 'id': 'sa/SACAT' },
        { 'name': 'South Australian Dental Professional Conduct Tribunal 1998', 'id': 'sa/SADPCT' },
        { 'name': 'South Australian Employment Tribunal 2015', 'id': 'sa/SAET' },
        { 'name': 'South Australian Industrial Relations Commission 1991', 'id': 'sa/SAIRComm' },
        { 'name': 'South Australian Industrial Relations Court 1992 ', 'id': 'sa/SAIRC' },
        { 'name': 'South Australian Industrial Reports 19161945', 'id': 'sa/SAIndRp' },
        { 'name': 'South Australian Law Reports 18631920', 'id': 'sa/SALawRp' },
        { 'name': 'South Australian Ombudsman FOI Determinations 2011', 'id': 'sa/SAOmbFOI' },
        { 'name': 'South Australian Psychological Board  Court Prosecutions 1992', 'id': 'sa/SAPSBCP' },
        { 'name': 'South Australian Psychological Board  Disciplinary Decisions 1991', 'id': 'sa/SAPSB' },
        { 'name': 'South Australian Residential Tenancies Tribunal  Residential Parks 2008', 'id': 'sa/SARTTRP' },
        { 'name': 'South Australian Residential Tenancies Tribunal 1996', 'id': 'sa/SARTT' },
        { 'name': 'South Australian Return to Work Premium Review 2019', 'id': 'sa/SARTWPR' },
        { 'name': 'South Australian Return to Work Premium Review Panel 2015', 'id': 'sa/SARTWPRP' },
        { 'name': 'South Australian State Reports 19201950', 'id': 'sa/SAStRp' },
        { 'name': 'South Australian Wardens Court 1994', 'id': 'sa/SAWC' },
        { 'name': 'South Australian WorkCover Levy Review Panel 20002012', 'id': 'sa/SAWLRP' },
        { 'name': 'South Australian WorkCover Premium Review Panel 2012', 'id': 'sa/SAWPRP' },
        { 'name': 'South Australian Workers Compensation Appeal Tribunal 1991', 'id': 'sa/SAWCAT' },
        { 'name': 'South Australian Workers Compensation Review Panel 19872000', 'id': 'sa/SAWCRP' },
        { 'name': 'South Australian Workers Compensation Tribunal 1996', 'id': 'sa/SAWCT' },
        { 'name': 'State Administrative Tribunal of Western Australia 2005', 'id': 'wa/WASAT' },
        { 'name': 'State Reports (New South Wales) 19011950', 'id': 'nsw/NSWStRp' },
        { 'name': 'Strata Schemes Board of New South Wales 19972001', 'id': 'nsw/NSWSSB' },
        { 'name': 'Strata Titles Referee of Western Australia 2004', 'id': 'wa/WASTR' },

        { 'name': 'Tasmanian Civil and Administrative Tribunal 2021', 'id': 'tas/TASCAT' },
        { 'name': 'Tasmanian Coroners Court 2001', 'id': 'tas/TasCorC' },
        { 'name': 'Tasmanian Guardianship and Administration Board 1998', 'id': 'tas/TASGAB' },
        { 'name': 'Tasmanian Health Practitioners Tribunal 2011', 'id': 'tas/TASHPT' },
        { 'name': 'Tasmanian Law Reports 18971940', 'id': 'tas/TASLawRp' },
        { 'name': 'Tasmanian Motor Accidents Compensation Tribunal 1976', 'id': 'tas/TASMACT' },
        { 'name': 'Tasmanian Planning Commission 2009', 'id': 'tas/TASPComm' },
        { 'name': 'Tasmanian Reports 19581991', 'id': 'tas/TASRp' },
        { 'name': 'Tasmanian State Reports 19411978', 'id': 'tas/TASStRp' },
        { 'name': 'Tenancy Tribunal of the ACT 19961998', 'id': 'act/ACTTT' },
        { 'name': 'The Victorian Law Times and Legal Observer 18561857', 'id': 'vic/VicLawTLegO' },
        { 'name': 'Town Planning Appeal Tribunal of Western Australia 20032004', 'id': 'wa/WATPAT' },
        { 'name': 'Victorian AntiDiscrimination Tribunal 19951998', 'id': 'vic/VADT' },
        { 'name': 'Victorian Civil and Administrative Tribunal 1998', 'id': 'vic/VCAT' },
        { 'name': 'Victorian Civil and Administrative Tribunal/Administrative Appeals Tribunal of Victoria 19851999', 'id': 'vic/VICCAT' },
        { 'name': 'Victorian Commission for Gambling and Liquor Regulation 2016', 'id': 'vic/VCGLR' },
        { 'name': 'Victorian Domestic Building Tribunal 19961998', 'id': 'vic/VDBT' },
        { 'name': 'Victorian Information Commissioner 2019', 'id': 'vic/VICmr' },
        { 'name': 'Victorian Law Reports 18741956', 'id': 'vic/VicLawRp' },
        { 'name': 'Victorian Legal Profession Tribunal 19972005', 'id': 'vic/VLPT' },
        { 'name': 'Victorian Legal Services Commissioner 2016', 'id': 'vic/VLSC' },
        { 'name': 'Victorian Mental Health Review Board 1987', 'id': 'vic/VMHRB' },
        { 'name': 'Victorian Mental Health Tribunal 2014', 'id': 'vic/VMHT' },
        { 'name': 'Victorian Planning Reports 19982013', 'id': 'vic/VicPRp' },
        { 'name': 'Victorian Racing Appeals Tribunal 19842009', 'id': 'vic/VRAT' },
        { 'name': 'Victorian Reports 19531996', 'id': 'vic/VicRp' },

        { 'name': "Webb, A'Beckett and William's Reports (Victoria) 18701872", 'id': 'vic/VicWABWRp' },
        { 'name': 'Western Australian Building Disputes Tribunal 20002011', 'id': 'wa/WABDT' },
        { 'name': 'Western Australian Industrial Relations Commission 19912016', 'id': 'wa/WAIRComm' },
        { 'name': 'Western Australian Industrial Relations Commission 2017', 'id': 'wa/WAIRC' },
        { 'name': 'Western Australian Information Commissioner 1994', 'id': 'wa/WAICmr' },
        { 'name': 'Western Australian Law Reports 18981958', 'id': 'wa/WALawRp' },
        { 'name': 'Western Australian Warden of Mines 1979', 'id': 'wa/WAWM' },
        { 'name': 'Workers Compensation Commission of New South Wales  Presidential 2002', 'id': 'nsw/NSWWCCPD' },
        { 'name': 'Workers Rehabilitation and Compensation Tribunal of Tasmania 2004', 'id': 'tas/TASWRCT' },
        { 'name': "Wyatt and Webb's Reports (Victoria) 18611863", 'id': 'vic/VicWWRp' },
        { 'name': "Wyatt, Webb and A'Beckett's Reports (Victoria) 18641869", 'id': 'vic/VicWWABRp' },
      ],
      "Others": [
        // high court
        { 'name': 'High Court of Australia 1903', 'id': 'cth/HCA' },
        { 'name': 'High Court of Australia Special Leave Dispositions 2008', 'id': 'cth/HCASL' },
        { 'name': 'High Court of Australia Transcripts 1983', 'id': 'cth/HCATrans' },

        // federal
        { 'name': 'Federal Court of Australia  Full Court 2002', 'id': 'cth/FCAFC' },
        { 'name': 'Federal Court of Australia 1977', 'id': 'cth/FCA' },

        // family
        { 'name': 'Family Court of Australia  Full Court 2008', 'id': 'cth/FamCAFC' },
        { 'name': 'Family Court of Australia 1976', 'id': 'cth/FamCA' },

        // federal circuit
        { 'name': 'Federal Circuit Court of Australia 2013', 'id': 'cth/FCCA' },
        { 'name': 'Federal Circuit and Family Court of Australia  Division 1 Appellate Jurisdiction 2021', 'id': 'cth/FedCFamC1A' },
        { 'name': 'Federal Circuit and Family Court of Australia  Division 1 First Instance 2021', 'id': 'cth/FedCFamC1F' },
        { 'name': 'Federal Circuit and Family Court of Australia  Division 2 General Federal Law 2021', 'id': 'cth/FedCFamC2G' },
        { 'name': 'Federal Circuit and Family Court of Australia  Division 2 Family Law 2021', 'id': 'cth/FedCFamC2F' },

        // magistrates
        { 'name': 'Federal Magistrates Court of Australia  Family Law 20002013', 'id': 'cth/FMCAfam' },
        { 'name': 'Federal Magistrates Court of Australia 20002013', 'id': 'cth/FMCA' },

        // others
        { 'name': 'Federal Privacy Commissioner of Australia Case Notes 19892011', 'id': 'cth/PrivCmrA' },
        { 'name': 'Federal Privacy Commissioner of Australia Complaint Determinations 19932004', 'id': 'cth/PrivCmrACD' },
        { 'name': 'Administrative Appeals Tribunal 1976', 'id': 'cth/AATA' },
        { 'name': 'Argus Law Reports 18951950', 'id': 'cth/ArgusLawRp' },
        { 'name': 'Argus Law Reports, Current Notes 18961950', 'id': 'cth/ArgusLawRpCN' },
        { 'name': 'Australia Domain Name 2003', 'id': 'cth/AUDND' },
        { 'name': 'Australian Coal Industry Tribunal 19401995', 'id': 'cth/ACIndT' },
        { 'name': 'Australian Competition Tribunal 1997', 'id': 'cth/ACompT' },
        { 'name': 'Australian Designs Office 1982', 'id': 'cth/ADO' },
        { 'name': 'Australian Industrial Relations Commission  Full Bench 20072009', 'id': 'cth/AIRCFB' },
        { 'name': 'Australian Industrial Relations Commission 19832009', 'id': 'cth/AIRC' },
        { 'name': 'Australian Information Commissioner 2011', 'id': 'cth/AICmr' },
        { 'name': 'Australian Information Commissioner Case Notes 2011', 'id': 'cth/AICmrCN' },
        { 'name': 'Australian Patent Office 1981', 'id': 'cth/APO' },
        { 'name': "Australian Plant Breeder's Rights Office Decisions 2016", 'id': 'cth/APBRO' },
        { 'name': 'Australian Takeovers Panel 2000', 'id': 'cth/ATP' },
        { 'name': 'Australian Trade Marks Office 1991', 'id': 'cth/ATMO' },
        { 'name': 'Australian Trade Marks Office Geographical Indication 2008', 'id': 'cth/ATMOGI' },
        { 'name': 'Commonwealth Arbitration Reports 19051993', 'id': 'cth/CthArbRp' },
        { 'name': 'Copyright Tribunal 1982', 'id': 'cth/ACopyT' },
        { 'name': 'Defence Force Discipline Appeal Tribunal 1999', 'id': 'cth/ADFDAT' },
        { 'name': 'Fair Work Australia  Enterprise Agreement 20092012', 'id': 'cth/FWAA' },
        { 'name': 'Fair Work Australia  Full Bench 20092012', 'id': 'cth/FWAFB' },
        { 'name': 'Fair Work Australia 20092012', 'id': 'cth/FWA' },
        { 'name': 'Fair Work Commission  Enterprise Agreement 2013', 'id': 'cth/FWCA' },
        { 'name': 'Fair Work Commission  Full Bench 2013', 'id': 'cth/FWCFB' },
        { 'name': 'Fair Work Commission  General Manager and Delegates 2013', 'id': 'cth/FWCD' },
        { 'name': 'Fair Work Commission 2013', 'id': 'cth/FWC' },
        { 'name': 'Human Rights and Equal Opportunity Commission 19852001', 'id': 'cth/HREOCA' },
        { 'name': 'Immigration Review Tribunal 19901999', 'id': 'cth/IRTA' },
        { 'name': 'Industrial Relations Court of Australia 19942002', 'id': 'cth/IRCA' },
        { 'name': 'Insurance Ombudsman Service Limited Privacy Compliance Committee Complaint Determinations 20042005', 'id': 'cth/IOSPCC' },
        { 'name': 'Local Court of the Northern Territory 2016', 'id': 'nt/NTLC' },
        { 'name': 'Migration Review Tribunal 19992015', 'id': 'cth/MRTA' },
        { 'name': 'National Native Title Tribunal 1994', 'id': 'cth/NNTTA' },
        { 'name': 'Northern Territory AntiDiscrimination Commission 1996', 'id': 'nt/NTADComm' },
        { 'name': 'Northern Territory Civil and Administrative Tribunal 2015', 'id': 'nt/NTCAT' },
        { 'name': 'Northern Territory Consumer Affairs Residential Building Disputes 2016', 'id': 'nt/NTCARB' },
        { 'name': 'Northern Territory Coroners Court 1979', 'id': 'nt/NTCorC' },
        { 'name': 'Northern Territory Health Professional Review Tribunal 2010', 'id': 'nt/NTHPRT' },
        { 'name': 'Northern Territory Judgments 19181976', 'id': 'nt/NTJud' },
        { 'name': 'Northern Territory Mental Health Review Tribunal 2015', 'id': 'nt/NTMHRT' },
        { 'name': 'Northern Territory Residential Tenancies Commissioner 2006', 'id': 'nt/NTRTCmr' },
        { 'name': 'Privy Council Appeals 19031980', 'id': 'cth/UKPCHCA' },
        { 'name': 'Refugee Review Tribunal 19932015', 'id': 'cth/RRTA' },
        { 'name': 'Superannuation Complaints Tribunal of Australia 1995', 'id': 'cth/SCTA' },
      ],
    },
  },

  'CAN': {
    states: [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Nova Scotia",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan"
    ],
    hierarchy: {
      "Supreme Courts": "(Supreme Court)|(Court of Appeal)|(Court of Queen's Bench)|(Superior Court)",
      "Intermediate Courts": "(Provincial Court)|(Family Court)",
    },
    courts: {
      "Canada Jurisdiction": [
        // Supreme Court
        { "name": "Supreme Court of British Columbia","id": "21"},
        {"name": "Supreme Court of Canada","id": "1"},
        {"name": "Supreme Court of Canada - Applications for Leave","id": "2"},
        {"name": "Supreme Court of Newfoundland and Labrador","id": "99"},
        {"name": "Supreme Court of Nova Scotia","id": "87"},
        {"name": "Supreme Court of Nova Scotia (Family Division)","id": "88"},
        {"name": "Supreme Court of Prince Edward Island","id": "96"},
        {"name": "Supreme Court of Yukon","id": "102"},
        {"name": "Supreme Court of the Northwest Territories","id": "106"},
        
        {"name": "Court Martial Appeal Court of Canada","id": "7"},
        {"name": "Courts Martial","id": "8"},

        {"name": "Court of Appeal for British Columbia","id": "20"},
        {"name": "Court of Appeal for Ontario","id": "45"},
        {"name": "Court of Appeal for Saskatchewan","id": "34"},
        {"name": "Court of Appeal for the Northwest Territories","id": "105"},
        { "name": "Court of Appeal of Alberta","id": "29"},
        {"name": "Court of Appeal of Manitoba","id": "42"},
        {"name": "Court of Appeal of New Brunswick","id": "81"},
        {"name": "Court of Appeal of Newfoundland and Labrador","id": "98"},
        {"name": "Court of Appeal of Nunavut","id": "111"},
        {"name": "Court of Appeal of Quebec","id": "67"},
        {"name": "Court of Appeal of Yukon","id": "101"},
        {"name": "Nova Scotia Court of Appeal","id": "86"},
        {"name": "Prince Edward Island Court of Appeal","id": "95"},
                        
        {"name": "Federal Court of Appeal","id": "3"},
        {"name": "Provincial Court","id": "83"},
        {"name": "Provincial Court of British Columbia","id": "22"},
        {"name": "Provincial Court of Manitoba","id": "44"},
        {"name": "Provincial Court of Newfoundland and Labrador","id": "100"},
        {"name": "Provincial Court of Nova Scotia","id": "89"},
        {"name": "Provincial Court of Prince Edward Island","id": "97"},
        {"name": "Provincial Court of Saskatchewan","id": "36"},
        {"name": "Superior Court","id": "68"},
        {"name": "Superior Court of Justice", "id": "46"},
        {"name": "Territorial Court of Yukon", "id": "103"},
        {"name": "Territorial Court of the Northwest Territories", "id": "107"},
        {"name": "Federal Court", "id": "4"},
        {"name": "Tax Court of Canada", "id": "5"},
        {"name": "Administrative Tribunal of Québec", "id": "73"},
        {"name": "Agriculture, Food & Rural Affairs Appeal Tribunal", "id": "50"},
        {"name": "Alberta Land and Property Rights Tribunal", "id": "32"},
        {"name": "Appeal Tribunal under the Medical Profession Act", "id": "40"},
        {"name": "British Columbia Employment Standards Tribunal", "id": "23"},
        {"name": "British Columbia Human Rights Tribunal", "id": "24"},
        {"name": "British Columbia Workers' Compensation Appeal Tribunal", "id": "25"},
        {"name": "Canada Agricultural Review Tribunal", "id": "9"},
        {"name": "Canadian Human Rights Tribunal", "id": "10"},
        {"name": "Canadian International Trade Tribunal", "id": "11"},
        {"name": "Capital Markets Tribunal", "id": "51"},
        {"name": "Civil Resolution Tribunal of British Columbia", "id": "26"},
        {"name": "Competition Tribunal", "id": "12"},
        {"name": "Condominium Authority Tribunal", "id": "52"},
        {"name": "Energy Resource Appeal Tribunal", "id": "27"},
        {"name": "Environmental Protection Tribunal of Canada", "id": "13"},
        {"name": "Environmental Review Tribunal", "id": "53"},
        {"name": "Financial Services Tribunal", "id": "28"},
        {"name": "Financial Services Tribunal", "id": "54"},
        {"name": "Financial and Consumer Services Tribunal", "id": "84"},
        {"name": "Horse Racing Alberta Appeal Tribunal", "id": "33"},
        {"name": "Human Rights Tribunal", "id": "70"},
        {"name": "Human Rights Tribunal of Ontario", "id": "55"},
        {"name": "Law Society Tribunal", "id": "56"},
        {"name": "Local Planning Appeal Tribunal", "id": "57"},
        {"name": "Mining and Lands Tribunal", "id": "58"},
        {"name": "Northwest Territories Assessment Appeal Tribunal", "id": "110"},
        {"name": "Northwest Territories and Nunavut Workers’ Compensation Appeals Tribunal", "id": "109"},
        {"name": "Northwest Territories and Nunavut Workers’ Compensation Appeals Tribunal", "id": "114"},
        {"name": "Nova Scotia Labour Standards Tribunal", "id": "93"},
        {"name": "Nova Scotia Workers' Compensation Appeals Tribunal", "id": "94"},
        {"name": "Nunavut Human Rights Tribunal", "id": "115"},
        {"name": "Occupational Health and Safety Tribunal Canada", "id": "14"},
        {"name": "Ontario Land Tribunal", "id": "60"},
        {"name": "Ontario Licence Appeal Tribunal", "id": "61"},
        {"name": "Ontario Pay Equity Hearings Tribunal", "id": "62"},
        {"name": "Ontario Physicians and Surgeons Discipline Tribunal", "id": "63"},
        {"name": "Ontario Social Benefits Tribunal", "id": "64"},
        {"name": "Ontario Special Education (English) Tribunal", "id": "65"},
        {"name": "Ontario Workplace Safety and Insurance Appeals Tribunal", "id": "66"},
        {"name": "Professions Tribunal", "id": "71"},
        {"name": "Public Servants Disclosure Protection Tribunal", "id": "15"},
        {"name": "Public Service Staffing Tribunal", "id": "16"},
        {"name": "Saskatchewan Human Rights Tribunal", "id": "41"},
        {"name": "Social Security Tribunal of Canada", "id": "17"},
        {"name": "Specific Claims Tribunal Canada", "id": "18"},
        {"name": "Transportation Appeal Tribunal of Canada", "id": "19"},
        {"name": "Tribunal administratif des marchés financiers", "id": "76"},
        {"name": "Tribunal administratif du logement", "id": "77"},
        {"name": "Tribunal administratif du travail", "id": "78"},
        {"name": "Tribunal d'arbitrage (RQ and CARRA)", "id": "80"},
        {"name": "Tribunal d'arbitrage (performing, recording and film artists)", "id": "79"},
        {"name": "Workers’ Compensation Appeals Tribunal", "id": "85"},
        // Others
        {"name": "Alberta Court of Justice", "id": "31"},
        {"name": "Court of King's Bench for Saskatchewan", "id": "35"},
        {"name": "Court of King's Bench of Alberta", "id": "30"},
        {"name": "Court of King's Bench of Manitoba", "id": "43"},
        {"name": "Court of King's Bench of New Brunswick", "id": "82"},
        {"name": "Court of Quebec", "id": "69"},
        {"name": "Divisional Court", "id": "47"},
        {"name": "Exchequer Court of Canada", "id": "6"},
        {"name": "Labour Court", "id": "75"},
        {"name": "Municipal Courts", "id": "72"},
        {"name": "Nova Scotia Family Court", "id": "92"},
        {"name": "Nova Scotia Probate Court", "id": "91"},
        {"name": "Nunavut Court of Justice", "id": "112"},
        {"name": "Ontario Court of Justice", "id": "49"},
        {"name": "Ontario Court of the Drainage Referee", "id": "59"},
        {"name": "Saskatchewan District Court", "id": "37"},
        {"name": "Saskatchewan Surrogate Court", "id": "38"},
        {"name": "Saskatchewan Unified Family Court", "id": "39"},
        {"name": "Small Claims Court", "id": "48"},
        {"name": "Small Claims Court", "id": "90"},
        {"name": "Small Claims Court of the Yukon", "id": "104"},
        {"name": "Youth Justice Court", "id": "108"},
        {"name": "Youth Justice Court of Nunavut", "id": "113"}
      ],
    },
  },

  'NZ': {
    states: [
      "New Zealand",
      "Auckland",
      "Bay of Plenty|(Tauranga)",
      "Canterbury|(Environment)|(Family)",
      "Gisborne",
      "Hawke's Bay",
      "Manawatu-Wanganui",
      "Marlborough",
      "Nelson",
      "Northland",
      "Otago|(Queenstown)|(Environment)",
      "Southland",
      "Taranaki",
      "Waikato|(Hamilton)",
      "Wellington",
      "West Coast",
      "Chatham Islands"
    ],
    hierarchy: {
      "District Court": "(District Court)|(District)",
      "High Court": "(High Court)",
      "Family Court": "(Family Court)",
      "Tribunals": "(Tribunal)|(Employment Relations)",
      "Specialist Courts": "(Environment Court)",
    },
    courts: {
      "New Zealand Jurisdiction":[
        // District Courts
        { "name": "District Court of New Zealand 1981", "id": "NZDC" },
        { "name": "Family Court of New Zealand 1997", "id": "NZFC" },
        { "name": "New Zealand District Licensing Committee Auckland 2014", "id": "NZDLCAK" },
        { "name": "New Zealand District Licensing Committee Hamilton 2014", "id": "NZDLCHAM" },
        { "name": "New Zealand District Licensing Committee Porirua 2014", "id": "NZDLCPOR" },
        { "name": "New Zealand District Licensing Committee Queenstown Lakes District Council 2015", "id": "NZDLCQLDC" },
        { "name": "New Zealand District Licensing Committee Tauranga 2021", "id": "NZDLCTGA" },
        { "name": "New Zealand District Licensing Committee Waipa 2016", "id": "NZDLCWP" },
        { "name": "New Zealand District Licensing Committee Wellington 2014", "id": "NZDLCWN" },
        { "name": "New Zealand Youth Court 2005", "id": "NZYC" },
        // High Courts
        {'name': 'Environment Court of New Zealand 1996','id': 'NZEnvC'},
        { 'name': 'High Court of New Zealand 1847', 'id': 'NZHC' },
        {'name': 'Social Security Appeal Authority of New Zealand Decisions 2003','id': 'NZSSAA'},
        {'name': 'Student Allowance Appeal Authority of New Zealand Decisions 2004','id': 'NZSAAA'},
        // Tribunals
         { "name": "New Zealand Agricultural Tribunal 1979-1985", "id": "NZAgrT" },
          { "name": "New Zealand Aircrew Industrial Tribunal 1981-1987", "id": "NZAIT" },
          { "name": "New Zealand Broadcasting Tribunal 1980-1990", "id": "NZBT" },
          { "name": "New Zealand Canterbury Earthquakes Insurance Tribunal 2019", "id": "NZCEIT" },
          { "name": "New Zealand Complaints Review Tribunal Decisions 1993-2007", "id": "NZCRT" },
          { "name": "New Zealand Copyright Tribunal Decisions 1977", "id": "NZCopyT" },
          { "name": "New Zealand Deportation Review Tribunal Decisions 1998-2010", "id": "NZDRT" },
          { "name": "New Zealand Disputes Tribunal Decisions 2006", "id": "NZDispT" },
          { "name": "New Zealand Employment Tribunal 1999-2003", "id": "NZEmpT" },
          { "name": "New Zealand Equal Opportunities Tribunal 1980-1993", "id": "NZEOT" },
          { "name": "New Zealand Health Practitioners Disciplinary Tribunal 2004", "id": "NZHPDT" },
          { "name": "New Zealand Human Rights Review Tribunal Decisions 2002", "id": "NZHRRT" },
          { "name": "New Zealand Immigration Advisers Complaints and Disciplinary Tribunal 2010", "id": "NZIACDT" },
          { "name": "New Zealand Immigration and Protection Tribunal Decisions 2010", "id": "NZIPT" },
          { "name": "New Zealand Indecent Publications Tribunal 1964-1996", "id": "NZIndPubT" },
          { "name": "New Zealand Land Valuation Tribunal Decisions 1994", "id": "NZLVT" },
          { "name": "New Zealand Lawyers and Conveyancers Disciplinary Tribunal Decisions 2009", "id": "NZLCDT" },
          { "name": "New Zealand Legal Aid Tribunal 2011", "id": "NZLAT" },
          { "name": "New Zealand Medical Practitioners Disciplinary Tribunal 1997-2010", "id": "NZMPDT" },
          { "name": "New Zealand Mental Health Review Tribunal 2009", "id": "NZMHRT" },
          { "name": "New Zealand Motor Vehicle Disputes Tribunal Decisions 1987", "id": "NZMVDT" },
          { "name": "New Zealand Planning Tribunal 1978-1996", "id": "NZPT" },
          { "name": "New Zealand Real Estate Agents Disciplinary Tribunal 2010", "id": "NZREADT" },
          { "name": "New Zealand Sports Tribunal 2003", "id": "NZST" },
          { "name": "New Zealand Teachers Disciplinary Tribunal 2005", "id": "NZTDT" },
          { "name": "New Zealand Victims' Special Claims Tribunal 2009", "id": "NZVSCT" },
          { "name": "New Zealand Weathertight Homes Tribunal Decisions 2003", "id": "NZWHT" },
          { "name": "New Zealand Weathertight Homes Tribunal Eligibility Decisions 2008", "id": "NZWHTE" },
          // Supreme Courts
          { 'name': 'Supreme Court of New Zealand 2004', 'id': 'NZSC' },
          // Court of Appeal
          { "name": "Court of Appeal of New Zealand 1888", "id": "NZCA" },
          { "name": "Courts Martial Appeal Court of New Zealand 1972", "id": "NZCMAC" },
          { "name": "Employment Court of New Zealand 1988", "id": "NZEmpC" },
          { "name": "Labour Court of New Zealand 1987-1991", "id": "NZLabC" },
          { "name": "Maori Appellate Court of New Zealand Decisions 1948", "id": "NZMAC" },
          { "name": "Maori Land Court of New Zealand Decisions 1948", "id": "NZMLC" },
          { "name": "New Zealand Court of Appeal Reports 1861-1877", "id": "NZCARp" },
          { "name": "New Zealand Employment Relations Authority Decisions 2000", "id": "NZERA" },
          { "name": "New Zealand Health and Safety in Employment Decisions 1996", "id": "NZHSE" },
          { "name": "New Zealand Ministry of Business, Innovation and Employment Building Determinations 1992", "id": "NZMBIEBldg" },
          // Others
          { "name": "Fenton's Important Judgments (New Zealand) 1866-1878", "id": "NZFImpJud" },
          { "name": "Intellectual Property Office of New Zealand Designs 1958", "id": "NZIPODES" },
          { "name": "Intellectual Property Office of New Zealand Patents 1957", "id": "NZIPOPAT" },
          { "name": "Intellectual Property Office of New Zealand Plant Variety Rights 1984", "id": "NZIPOPVR" },
          { "name": "Intellectual Property Office of New Zealand Trade Marks 1955", "id": "NZIPOTM" },
          { "name": "Macassey's Reports New Zealand 1859-1872", "id": "NZMacasRp" },
          { "name": "New Zealand Accident Compensation Appeal Authority Decisions 1978", "id": "NZACAA" },
          { "name": "New Zealand Accident Compensation Appeals ACC Appeal Decisions 1993", "id": "NZACC" },
          { "name": "New Zealand Accident Compensation Reports 1976-1984", "id": "NZACCRp" },
          { "name": "New Zealand Advertising Standards Authority 2001", "id": "NZASA" },
          { "name": "New Zealand Air Services Licensing Appeal Authority 1977-1983", "id": "NZASLAA" },
          { "name": "New Zealand Alcohol Regulatory and Licensing Authority 2013", "id": "NZARLA" },
          { "name": "New Zealand Appeals to the Privy Council 1851-2013", "id": "NZPC" },
          { "name": "New Zealand Arbitration Commission 1988-1991", "id": "NZArbCom" },
          { "name": "New Zealand Arbitration Court 1986", "id": "NZArbC" },
          { "name": "New Zealand Arbitration Court Judgments 1979-1986", "id": "NZArbCJud" },
          { "name": "New Zealand Book of Awards Decisions 1960", "id": "NZBkAwdDec" },
          { "name": "New Zealand Broadcasting Standards Authority Decisions 1995", "id": "NZBSA" },
          { "name": "New Zealand Colonial Law Journal 1865-1875", "id": "NZColLawJl" },
          { "name": "New Zealand Commerce Commission Decisions 1975", "id": "NZComComm" },
          { "name": "New Zealand Compensation Court 1967", "id": "NZCompC" },
          { "name": "New Zealand Coroners Court 2007", "id": "NZCorC" },
          { "name": "New Zealand Customs Appeal Authority Decisions 1997", "id": "NZCAA" },
          { "name": "New Zealand Domain Name Commission 2006", "id": "NZDNC" },
          { "name": "New Zealand Film and Literature Board of Review 1997", "id": "NZFLBR" },
          { "name": "New Zealand Gazette Law Reports 1876-1953", "id": "NZGazLawRp" },
          { "name": "New Zealand Human Rights Commissioner Case Notes 1988", "id": "NZHRCCN" },
          { "name": "New Zealand Industrial Court Judgments 1974-1978", "id": "NZICJud" },
          { "name": "New Zealand Industrial Law Reports 1987-1990", "id": "NZILawRp" },
          { "name": "New Zealand Jurist Reports 1859-1879", "id": "NZJurRp" },
          { "name": "New Zealand Land Valuation Cases 1961-1993", "id": "NZLVCas" },
          { "name": "New Zealand Legal Aid Appeal Authority 1976-1993", "id": "NZLAAA" },
          { "name": "New Zealand Legal Aid Review Authority 1992-2001", "id": "NZLARA" },
          { "name": "New Zealand Legal Aid Review Panel 2001-2011", "id": "NZLARP" },
          { "name": "New Zealand Legal Complaints Review Officer Decisions 2009", "id": "NZLCRO" },
          { "name": "New Zealand Licensing Authority of Secondhand Dealers and Pawnbrokers Decisions 2005", "id": "NZSHD" },
          { "name": "New Zealand Licensing Control Commission 1970-1990", "id": "NZLCC" },
          { "name": "New Zealand Liquor Licensing Authority Decisions 1990-2012", "id": "NZLLA" },
          { "name": "New Zealand Lost Cases 1842-1882", "id": "NZLostC" },
          { "name": "New Zealand Magistrates Court 1976-1980", "id": "NZMC" },
          { "name": "New Zealand Magistrates' Court Reports 1898-1952", "id": "NZMCRp" },
          { "name": "New Zealand Matrimonial Property Cases 1977-1982", "id": "NZMPC" },
          { "name": "New Zealand Media Council 2018", "id": "NZMediaC" },
          { "name": "New Zealand Motor Spirits Licensing Appeal Authority 1965", "id": "NZMSLAA" },
          { "name": "New Zealand Native Appellate Court 1894-1947", "id": "NZNAC" },
          { "name": "New Zealand Native Land Court 1869-1945", "id": "NZNLC" },
          { "name": "New Zealand Online Media Standards Authority 2013-2016", "id": "NZOMSA" },
          { "name": "New Zealand Overtime and Shift Work Recognition Authority 1975-1982", "id": "NZOSWRA" },
          { "name": "New Zealand Pharmacy Authority 1978-1997", "id": "NZPhA" },
          { "name": "New Zealand Plumbers Gasfitters and Drainlayers Board 2018", "id": "NZPGDB" },
          { "name": "New Zealand Police Law Reports 1910-1968", "id": "NZPoliceLawRp" },
          { "name": "New Zealand Press Council 1994-2018", "id": "NZPressC" },
          { "name": "New Zealand Privacy Commissioner Cases 1994", "id": "NZPrivCmr" },
          { "name": "New Zealand Private Security Personnel Licensing Authority 2019", "id": "NZPSPLA" },
          { "name": "New Zealand Real Estate Agents Authority 2010", "id": "NZREAA" },
          { "name": "New Zealand Real Estate Agents Licensing Board 1984-2009", "id": "NZREALB" },
          { "name": "New Zealand Refugee Status Appeals Authority 1991-2010", "id": "NZRSAA" },
          { "name": "New Zealand Removal Review Authority 1991-2010", "id": "NZRRA" },
          { "name": "New Zealand Residence Review Board 2006-2010", "id": "NZRRB" },
          { "name": "New Zealand Review Authority 2011", "id": "NZRA" },
          { "name": "New Zealand Taxation Review Authority Decisions 1984", "id": "NZTRA" },
          { "name": "New Zealand Town and Country Planning Appeal Board 1955-1983", "id": "NZTCPAB" },
          { "name": "New Zealand Trade Practices Appeal Authority 1959-1965", "id": "NZTPAA" },
          { "name": "New Zealand Trade Practices and Prices Commission 1959-1967", "id": "NZTPPCom" },
          { "name": "New Zealand Transport Charges Appeal Authority 1969-1981", "id": "NZTCAA" },
          { "name": "New Zealand Transport Licensing Appeal Authority 1968-1981", "id": "NZTLAA" },
          { "name": "New Zealand Workers Compensation Cases 1901-1940", "id": "NZWkrCompC" },
          { "name": "Ollivier, Bell, & Fitzgerald's Reports New Zealand 1878-1880", "id": "NZOBFitzRp" },
      ]
    },
  },

  'SG': {
    states: [
      "Singapore|()",
    ],
    hierarchy: {
      "Court of Appeal": "(Court of Appeal)",
      "High Court": "(High Court)",
      "State Court": "(District Court)|(Magistrates' Court)",
    },
    courts: {
      'Singapore Jurisdiction': [
        { 'name': 'Court of Appeal 2005', 'id': 'SGCA' },
        { 'name': 'High Court 2005', 'id': 'SGHC' },
        { 'name': 'District Court of Singapore 2006', 'id': 'SGDC' },
        { 'name': "Magistrates' Court of Singapore 2006", 'id': 'SGMC' },
        { 'name': 'Intellectual Property Office of Singapore 1999', 'id': 'SGIPOS' },
      ],
    },
  },

  'MY': {
    states: [
      "Malaysia"
    ],
    hierarchy: {
      "Supreme Court": "(Federal Court)",
      "Intermediate Courts": "(Court of Appeal)|(High Courts)",
      "Subordinate Courts": "(Magistrates Courts)|(Sessions Courts)",
    },
    courts: {
      'Malaysia Jurisdiction': [
        { 'name': 'Federal Court of Malaysia', 'id': 1 },
        { 'name': 'Court of Appeal of Malaysia', 'id': 2 },
        { 'name': 'High Courts of Malaysia', 'id': 3 },
        { 'name': 'Magistrates Courts of Malaysia', 'id': 5 },
        { 'name': 'Sessions Courts of Malaysia', 'id': 4 },
      ]
    },
  },
}